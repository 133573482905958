var urlServer = "/push-notifications/register";
var firebaseConfig = {
    apiKey: "AIzaSyCZ6WqEOR_kPd2A5QCUIyyjjsQ8h1_N93Y",
    authDomain: "diario-democracia.firebaseapp.com",
    databaseURL: "https://diario-democracia.firebaseio.com",
    projectId: "diario-democracia",
    storageBucket: "diario-democracia.appspot.com",
    messagingSenderId: "67668348736",
    appId: "1:67668348736:web:505656bf3951196ffab4a5",
    measurementId: "G-W1LJDD32XS"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
const messaging = firebase.messaging();
messaging.requestPermission()
    .then(() => {
        return messaging.getToken()
    })
    .then(async (token) => {
        if (Cookies.get('registered_in_notifications') == true) {
            console.log('Ok');
        } else {
            let response = await post(urlServer, {push_id: token});
            console.log(response.message);
            Cookies.set('registered_in_notifications', 'true', {expires: 7200});
        }
    })
    .catch((error) => {
        console.log(error)
    })

async function post(url = "", data = {}) {
    const response = await fetch(url, {
        method: "post",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    return await response.json();
}

messaging.onMessage(notification => {
    console.log(notification)
    console.log('Todo correcto');
});




